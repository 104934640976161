<template>
	<div id="top">
		<nav class="main-nav" :class="{ 'show-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">X</li>
								<li v-for="item in topmenu[locale]" :key="item.filename">
									<nuxt-link :to="item.filename" @click="hideMobileNav">
										{{ item.header }}
									</nuxt-link>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ item.language }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<div class="overlay" @click="hideMobileNav" />
					</div>

					<div class="menu-button" @click="toggleMobileNav" />
					<nuxt-link class="logo fix-on-scroll" :to="{ path: localePath('/', locale), hash: '#top' }" />
					<div class="fixed-right">
						<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
							<div v-for="item in langmenu" :key="item.filename" class="language">
								<nuxt-link
									class="flag"
									:class="item.language"
									:to="item.filename"
									@click="hideMobileNav"
								/>
							</div>
						</div>
						<social-media :socials="socials" />
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const localePath = useLocalePath();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};
</script>

<style lang="scss" scoped>
.mobile-only {
	display: none !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	font-family: festaberegular, sans-serif;

	ul li {
		display: inline-block;
	}

	.nav-bar {
		margin-left: -100vw;
		transition: all 0.3s ease-in-out;
	}

	&.show-nav {
		.nav-bar {
			margin-left: 0;
		}

		.overlay {
			content: '';
			display: block;
			position: fixed;
			inset: 0;
			z-index: 101;
			background: rgba(0 0 0 / 25%);
		}
	}
}

.logo {
	width: 80px;
	height: 57px;
	background: url('~/assets/images/logo-leo-goudvisch-small-red.png') no-repeat center center;
	background-size: 80px;
	position: fixed;
	top: 30px;
	left: 0;
	right: 0;
	margin: 0 auto;
	opacity: 0;
	display: none;
	transition: opacity 0.3 ease-in-out;
}

.nav-bar {
	display: flex;
	flex-flow: row wrap;
	justify-content: stretch;
	align-items: center;
	padding: 20px 70px;
	z-index: 109;
	position: fixed;
	inset: 0 auto 0 0;
	background: #6a2b31;
}

.menu-button {
	position: fixed;
	top: 30px;
	left: 30px;
	z-index: 100;
	width: 34px;
	height: 34px;
	background: url('~/assets/images/menu-button-icon.png') no-repeat center center;
	background-size: 34px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.fixed-right {
	position: fixed;
	top: 30px;
	right: 30px;
	height: 40px;
	overflow: hidden;
	z-index: 100;
	text-align: right;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	align-items: center;

	.language-navigation {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		.language {
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			.flag {
				margin: 0 9px 0 0;
				width: 28px;
				height: 28px;
				opacity: 0.75;
				transition: opacity 0.3s ease-in-out;

				&.router-link-active,
				&.router-link-exact-active,
				&:hover {
					opacity: 1;
				}

				&::before {
					width: 28px;
					height: 28px;
					background-size: 28px;
				}
			}
		}
	}
}

.nav-content {
	flex-grow: 1;
	text-align: right;
	height: 100%;
	position: relative;
	z-index: 102;

	a {
		text-decoration: none;
		margin: 0;
		color: var(--cta-color);
		letter-spacing: 1px;
		font-size: 30px;
		text-transform: uppercase;
		font-weight: 400;

		&.active,
		&:hover {
			color: var(--cta-color-hover);
		}
	}

	ul {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		height: 100%;

		li {
			margin: 18px 0;
		}
	}

	.menu-close {
		color: var(--cta-color);
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 0;
		width: auto;
		right: 5px;
		padding: 0;
		margin: 0;
	}
}

.language {
	margin: 2px 0;

	.flag::before {
		content: '';
		width: 20px;
		position: relative;
		top: 0;
		height: 20px;
		margin-right: 6px;
		display: inline-block;
	}

	.flag.en::before {
		background: url('~/assets/images/en.png') no-repeat center center;
		background-size: 20px;
	}

	.flag.nl::before {
		background: url('~/assets/images/nl.png') no-repeat center center;
		background-size: 20px;
	}
}

.lang-nav {
	margin: 0 0 25px;

	img {
		width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}

	a {
		width: 100%;
		float: left;
		color: var(--body-color);
		text-decoration: none;
		margin: 3px 0;
		text-transform: uppercase;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;

		&.active {
			color: var(--cta-color);
		}
	}
}

.language-navigation:hover .lang-nav-sel,
.language-navigation:hover .fa-globe {
	color: var(--cta-color);
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: var(--body-color);
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 18px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	color: #d2cebe;
	margin: 0 18px 0 0;

	span {
		margin: 0 4px;
	}

	a {
		text-decoration: none;
	}

	svg {
		font-size: 16px;
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: var(--primary-background-color);
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	z-index: 90;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 15px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.language-navigation .fa-globe {
	margin: 0 5px 0 0;
	color: #d2cebe;
	transition: color 0.3s ease-in-out;
	font-size: 26px;
}

@media (max-width: 1000px) {
	.logo {
		opacity: 1;
		background: url('~/assets/images/logo-leo-goudvisch-small-red.png') no-repeat center center;
		background-size: 80px;
		top: 22px;
		display: block;
	}

	.main-nav {
		height: 106px;
		background: var(--primary-background-color);

		.nav-bar {
			position: fixed;
			inset: -100vh 0 auto;
			margin-left: 0;
		}

		&.show-nav {
			.nav-bar {
				inset: 106px 0 auto;
			}
		}
	}

	.menu-button {
		text-transform: uppercase;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 10px 0 0;
		cursor: pointer;

		svg {
			font-size: 22px;
		}

		span {
			margin: 0 0 0 5px;
		}
	}

	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
			margin: 30px 0 0;
			padding: 0 10px;
		}
	}

	.fixed-right {
		top: 28px;

		.language-navigation {
			display: none;
		}
	}

	.nav-bar .buttons .icon {
		display: unset;
	}

	.lang-nav {
		a {
			color: var(--cta-color);
			font-size: 20px;
		}
	}

	.nav-content {
		ul {
			justify-content: flex-start;

			li {
				margin: 1px 0;

				&:not(.lang-nav) {
					width: 100%;
					text-align: center;
				}

				&.menu-close {
					display: inline-block;
					text-align: right;
					position: relative;
					inset: auto;
					margin: -5px 0;
				}
			}
		}

		a {
			font-size: 24px;
		}
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons {
		.button {
			padding: 16px;
		}

		.menu-button {
			margin-right: 5px;

			.icon {
				font-size: 22px;
			}
		}

		.icon {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons span {
		display: none;
	}
}
</style>
