<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column2">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>

						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<!-- <social-media :socials="socials" /> -->
				</div>
				<div class="columns column3">
					<div v-parse-links v-html="defaults[locale].website.openingHours" />
				</div>
				<div class="columns column2">
					<h4>Links</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
				<div class="columns column2">
					<a href="https://www.goudvisch.nl/" target="_blank" class="footer-logo-goudvisch" />
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footers {
	color: var(--footer-color);
	position: relative;
	z-index: 2;

	a,
	span,
	:deep(a) {
		color: var(--footer-color);
		text-decoration: none;
	}

	h3,
	:deep(h3),
	h4,
	:deep(h4) {
		color: var(--footer-color);
		letter-spacing: 1px;
		font-weight: 500;
		margin: 0 0 5px;
	}

	h4,
	:deep(h4) {
		font-family: var(--body-font-family);
		font-size: 1.1em;
	}
}

.main-footer {
	padding: 80px 0 40px;
	font-size: 14px;

	.row {
		max-width: 1580px;
	}

	a,
	span {
		display: block;
	}

	a:hover {
		text-decoration: underline;
	}

	.footer-logo {
		width: 80px;
		height: 60px;
		background: url('~/assets/images/logo-leo-goudvisch-small-red.png') no-repeat center center;
		background-size: 80px;
	}

	.footer-logo-goudvisch {
		width: 70px;
		height: 61px;
		background: url('~/assets/images/logo-fam-goudvisch-small.png') no-repeat center center;
		background-size: 70px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 0 auto 10px;

		.footer-logo {
			margin: 60px 0 30px;
		}
	}
}

@media (max-width: 640px) {
	.main-footer {
		padding: 30px 5px;
	}
}
</style>
